import { Button, Center, ChakraProvider, Flex, Image, Spinner } from "@chakra-ui/react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from '../FullLogo_Transparent_NoBuffer.png'
import { ROUTES } from "..";
import firebase from '../firebase'
import "firebase/compat/auth";
import { store } from '../store'
import { Provider, useDispatch } from 'react-redux'
import { useEffect, useState } from "react";
import { api, useGetUserQuery } from "../store/api";
import { clearToken, setToken } from "../store/authSlice";
import { clearOnboarding } from "../store/onboardingSlice";
import Header from "../components/Header";
import { theme } from "../theme";
import ErrorBoundary from "../components/ErrorBoundary";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate()
  const [ready, setReady] = useState(false)
  const dispatch = useDispatch()

  const initUser = async () => {
    const token = await getBearerToken()
    if (!token) {
      navigate(ROUTES.LOGIN)
      return
    }
    dispatch(setToken(token))
    setReady(true)
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        navigate(ROUTES.LOGIN)
      } else {
        initUser()
      }
    });

  }, [])



  const { data, isFetching, error } = useGetUserQuery({}, { skip: !ready })

  useEffect(() => {
    if (error) {
      console.error(error)
    }

    // if (data) {
    //   console.log(data)
    // }

    // if (isFetching) {
    //   console.log('loading')
    // }
  }, [error])

  useEffect(() => {
    // console.log('ready', ready)
    debugger
    if (ready && data) {
      if (location.pathname.includes(ROUTES.APP) && !data.is_onboarding_complete) {
        navigate(ROUTES.ONBOARDING, { replace: true })
      } else if (location.pathname === ROUTES.APP) {
        navigate(ROUTES.PROGRESS_TRACKER)
      }
    }
  }, [ready, data, location.pathname])

  return (

    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <Flex flexDir="column" style={{ minHeight: "100%" }} bg="#EEEEEE">
          <Header />
          <Flex flex={1} justify="center">
            {
              isFetching || !ready ? (
                <Center position="absolute" top={0} left={0} right={0} bottom={0}>
                  <Spinner size="xl" />
                </Center>
              ) : <Outlet />
            }

          </Flex>
        </Flex>
      </ErrorBoundary>
    </ChakraProvider>
  )
}

const getBearerToken = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    try {
      const token = await user.getIdToken(true);
      // console.log('Bearer Token:', token);
      return token;
    } catch (error) {
      console.error('Error getting token:', error);
    }
  } else {
    // console.log('No user is signed in.');
  }
};