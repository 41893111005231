import { Button, CardHeader, VStack, Flex, Heading, Text, Select, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Radio, RadioGroup, Stack, Progress, Box, Textarea, Center } from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Welcome from './Welcome';
import BuyingLocation from './BuyingLocation';
import Budget from './Budget';
import Preapproval from './Preapproval';
import PropertyType from './PropertyType';
import IsFirstTimeHomebuyer from './IsFirstTimeHomebuyer';
import OnboardingProgress from './OnboardingProgress';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setBuyingState, setEstimatedBudget, setIsFirstTimeBuyer, setIsPreapproved, setPropertyType } from "../../store/onboardingSlice";
import { useGetUserQuery, useSubmitOnboardingAnswersMutation } from "../../store/api";
import { ROUTES } from "../..";
import { useNavigate } from "react-router-dom";

export default function Onboarding() {
    const navigate = useNavigate()
    const onboardingValues = useSelector((state: RootState) => state.onboarding)
    const dispatch = useDispatch()
    const [step, setStep] = useState<number>(1)
    const [answersSubmitted, setAnswersSubmitted] = useState(false)

    const { data: user, isFetching: isUserFetching, } = useGetUserQuery({})
    const [submitOnboardingAnswers, { isLoading }] = useSubmitOnboardingAnswersMutation()

    const updateBuyingState = (state: string) => {
        dispatch(setBuyingState(state))
        setStep(step + 1)
    }

    const updateEstimatedBudget = (budget: number) => {
        dispatch(setEstimatedBudget(budget))
        setStep(step + 1)
    }

    const updateIsPreapproved = (isPreapproved: boolean) => {
        dispatch(setIsPreapproved(isPreapproved))
        setStep(step + 1)
    }

    const updatePropertyType = (propertyType: string) => {
        dispatch(setPropertyType(propertyType))
        setStep(step + 1)
    }

    const updateIsFirstTimeBuyer = (isFirstTimeBuyer: boolean) => {
        dispatch(setIsFirstTimeBuyer(isFirstTimeBuyer))
        const buyingState = onboardingValues.buying_state
        const estimatedBudget = onboardingValues.estimated_budget
        const isPreapproved = onboardingValues.is_preapproved
        const propertyType = onboardingValues.property_type

        if (!answersSubmitted &&buyingState !== null && estimatedBudget !== null && isPreapproved !== null && propertyType !== null && isFirstTimeBuyer !== null) {
            debugger
            setAnswersSubmitted(true)
            submitAnswers({
                buyingState,
                estimatedBudget,
                isPreapproved,
                propertyType,
                isFirstTimeBuyer
            })
        }
    }

    const updateStartOnboarding = () => {
        setStep(2)
    }

    const submitAnswers = async ({ buyingState, estimatedBudget, isPreapproved, propertyType, isFirstTimeBuyer }: { buyingState: string, estimatedBudget: number, isPreapproved: boolean, propertyType: string, isFirstTimeBuyer: boolean }) => {
        const result = await submitOnboardingAnswers({
            buying_state: buyingState,
            estimated_budget: estimatedBudget,
            is_preapproved: isPreapproved,
            property_type: propertyType,
            is_first_time_buyer: isFirstTimeBuyer,
        })
    }

    useEffect(() => {
        if (user?.is_onboarding_complete) {
            navigate(ROUTES.PROGRESS_TRACKER)
        }
    }, [user])

    // useEffect(() => {
    //     debugger
    //     const buyingState = onboardingValues.buying_state
    //     const estimatedBudget = onboardingValues.estimated_budget
    //     const isPreapproved = onboardingValues.is_preapproved
    //     const propertyType = onboardingValues.property_type
    //     const isFirstTimeBuyer = onboardingValues.is_first_time_buyer

    //     if (!answersSubmitted &&buyingState !== null && estimatedBudget !== null && isPreapproved !== null && propertyType !== null && isFirstTimeBuyer !== null) {
    //         debugger
    //         setAnswersSubmitted(true)
    //         submitAnswers({
    //             buyingState,
    //             estimatedBudget,
    //             isPreapproved,
    //             propertyType,
    //             isFirstTimeBuyer
    //         })
    //     }
    // }, [onboardingValues])

    return (
        <Flex width="100%" justifyContent="center" align="center" px={4}>
            <Card borderBottomRightRadius={0} borderBottomLeftRadius={0} borderRadius={0} height="100%" maxW="1600px" flex={1} display="flex" flexDir="row" justifyContent="center">
                <VStack width="60%">
                <Box width="100%" display="flex" flexDir="column" height={400}>
                    {
                        step === 1 && <Welcome currentStep={step} onPress={updateStartOnboarding} />
                    }
                    {
                        step === 2 && <BuyingLocation currentStep={step} onPress={updateBuyingState} />
                    }
                    {
                        step === 3 && <Budget currentStep={step} onPress={updateEstimatedBudget} />
                    }
                    {
                        step === 4 && <Preapproval currentStep={step} onPress={updateIsPreapproved} />
                    }
                    {
                        step === 5 && <PropertyType currentStep={step} onPress={updatePropertyType} />
                    }
                    {
                        step === 6 && <IsFirstTimeHomebuyer currentStep={step} onPress={updateIsFirstTimeBuyer} />
                    }
                    {
                        isLoading && <Center height="100%" width="100%"><Progress size='lg' /></Center>
                    }
                </Box>
                </VStack>
                {/* <VStack borderTopRightRadius={0} flex={1} bg="#1c1c84">
                    <VStack p={10}>
                    <Heading mb={6} size="md" color="white">Need More Info?</Heading>
                    <Text color="lightgray">This panel updates Focused tips and advice for the current question. Need more help? Ask our assistant.</Text>
                    </VStack>
                    <VStack width="100%" flex={1}>
                        <VStack flex={1}>
                        </VStack>
                        <VStack borderTopRightRadius={16} borderTopLeftRadius={10} p={10} width="100%" flex={1} bg="#000042">
                            <VStack flex={1} justifyContent="center">
                                <Heading mb={6} size="md" color="white">How can we help?</Heading>
                            </VStack>
                            <VStack width="100%">
                                <Textarea resize="none" color="white" placeholder='Ask a question' />
                            </VStack>
                        </VStack>
                    </VStack>
                </VStack> */}
            </Card>
        </Flex>
    )
}