import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Alert, AlertIcon } from "@chakra-ui/react";
import { Field, Form, Formik, FieldProps, FormikProps, FieldInputProps } from 'formik';
import { ReactNode } from "react";
import firebase from '../firebase'
import "firebase/compat/auth";
import { ROUTES } from "..";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

export default function Login() {

  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('');

  function validateEmail(value: string) {
    let error
    if (!value || value.length === 0) {
      error = "Required"
    } else if (!value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      error = "Please enter a valid email"
    }

    return error
  }

  function validatePassword(value: string) {
    let error
    if (!value || value.length === 0) {
      error = "Required"
    }

    return error
  }

  function isFieldInvalid(form: FormikProps<any>, field: FieldInputProps<any>) {
    const error = form.errors[field.name] as string
    const isTouched = form.touched[field.name]
    return isTouched && error && error.length > 0 ? true : false
  }

  const handleLogin = async (email: string, password: string) => {
    try {
      const auth = firebase.auth();
      const userCredential = await auth.signInWithEmailAndPassword(email, password)
      // console.log((userCredential))
      // debugger
      const user = userCredential.user;
      navigate(ROUTES.APP);
    } catch (error) {
      setErrorMessage('The username or password is incorrect. Please try again.');
      console.error(error)
    } finally {

    }

  }

  return (
    <Flex width={{base: '90%', md: '80%', lg: 500 }} justifyContent="center" align="center" mt={{ base: "175px", md: "250px" }} px={4}>
      <Card flex={1} height="fit-content" position="relative" top={-100}>
        <CardHeader>
          <Heading textAlign="center" size="md">Login</Heading>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={async (values, actions) => {
              await handleLogin(values.email, values.password)
            }}
          >
            {(props) => (
              <Form>
                <Flex direction="column" gap={6}>
                  <Field name='email' validate={validateEmail}>
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={isFieldInvalid(form, field)}>
                        <FormLabel>Email</FormLabel>
                        <Input {...field} placeholder='email' />
                        <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name='password' validate={validatePassword}>
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={isFieldInvalid(form, field)}>
                        <FormLabel>Password</FormLabel>
                        <Input {...field} placeholder='password' type="password" />
                        <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {errorMessage && (
                    <Alert status="error" mb={4}>
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                  <Button
                    mt={2}
                    width="100%"
                    colorScheme='blue'
                    isLoading={props.isSubmitting}
                    type='submit'
                  >
                    Login
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Flex>
  )
}