import { CardHeader, Heading, VStack, Text, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Button, HStack, SliderMark } from "@chakra-ui/react";
import OnboardingProgress from "./OnboardingProgress";
import { useState } from "react";

const startingValue = 100000

const Budget = ({ onPress, currentStep }: { onPress: (amount: number) => void, currentStep: number }) => {
    const [sliderValue, setSliderValue] = useState(startingValue);
    
    return (
        <>
            <CardHeader>
                <OnboardingProgress currentStep={currentStep} />
                <Heading textAlign="center" size="md">What is your budget?</Heading>
            </CardHeader>
            <VStack align="center" height="100%" alignItems="center">
                <VStack width="100%" maxW="400px" justifyContent="space-between" height="100%">
                    <VStack width="100%">
                        <Text>What’s your estimated home-buying budget?</Text>
                        <Slider step={1000} mt={2} aria-label='slider-ex-1' defaultValue={startingValue} min={0} max={1000000} onChange={(val) => setSliderValue(val)}>
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                        <Text mt={4} fontSize="24px" fontWeight={400}>
                            {sliderValue === 1000000 ? '$1M+' : sliderValue / 1000 === 0 ? '$0' : `$${sliderValue / 1000}K`}
                        </Text>
                    </VStack>
                    <Button maxW="400px" width="100%" onClick={() => onPress(sliderValue)} colorScheme="blue">Continue</Button>
                </VStack>
            </VStack>
        </>
    );
};

export default Budget;
