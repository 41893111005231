import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from './routes/root';
import ErrorPage from './routes/error';
import Login from './routes/login';
import App from './routes/app';
import Tasks from './routes/tasks';
import SavedListings from './routes/savedListings';
import WriteOffer from './routes/writeOffer/writeOffer';
import PartiesAndProperty from './routes/partiesAndProperty';
import BuyerInfo from './routes/writeOffer/partiesAndProperty/buyerInfo';
import SellerInfo from './routes/writeOffer/partiesAndProperty/sellerInfo';
import OfferRoom from './routes/offerRoom';
import FAQ from './routes/FAQ';
import ProgressTracker from './routes/progressTracker';
import SignUp from './routes/signUp';
import Onboarding from './routes/onboarding/onboarding';
import { Provider } from 'react-redux';
import { store } from './store';

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  SIGN_UP: '/signUp',
  FAQ: '/faq',
  APP: '/app',
  ONBOARDING: '/app/onboarding',
  SAVED_LISTINGS: '/app/savedListings',
  OFFER_ROOM: '/app/offerRoom',
  WRITE_OFFER: '/app/writeOffer',
  PARTIES_AND_PROPERTY: '/app/writeOffer/partiesAndProperty',
  BUYER_INFO: '/app/writeOffer/partiesAndProperty/buyerInfo',
  SELLER_INFO: '/app/writeOffer/partiesAndProperty/sellerInfo',
  PROGRESS_TRACKER: '/app/progressTracker'
}

const router = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.SIGN_UP,
        element: <SignUp />,
      },
      {
        path: ROUTES.FAQ,
        element: <FAQ />
      }
    ]
  },
  {
    path: "app",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROUTES.ONBOARDING,
        element: <Onboarding />,
      },
      {
        path: ROUTES.SAVED_LISTINGS,
        element: <SavedListings />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: ROUTES.OFFER_ROOM,
        element: <OfferRoom />,
      },
      {
        path: ROUTES.PROGRESS_TRACKER,
        element: <ProgressTracker />,
      },
      // {
      //   path: ROUTES.WRITE_OFFER,
      //   element: <WriteOffer />,
      //   children: [
      //     {
      //       path: ROUTES.PARTIES_AND_PROPERTY,
      //       element: <PartiesAndProperty />,
      //       children: [
      //         {
      //           path: ROUTES.BUYER_INFO,
      //           element: <BuyerInfo />,
      //         },
      //         {
      //           path: ROUTES.SELLER_INFO,
      //           element: <SellerInfo />,
      //         },
      //       ]
      //     },
      //   ]
      // },
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
