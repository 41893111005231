import { CardHeader, Heading, VStack, Text, Select, Button } from "@chakra-ui/react";
import OnboardingProgress from "./OnboardingProgress";
import { States } from './StatesEnum';
import { useState } from "react";

const BuyingLocation = ({ onPress, currentStep }: { onPress: (state: string) => void, currentStep: number }) => {
    const [selectedState, setSelectedState] = useState<string>();

    const handleContinue = () => {
        if (selectedState !== undefined) {
            onPress(selectedState);
        }
    };

    return (
        <>
            <CardHeader>
                <OnboardingProgress currentStep={currentStep} />
                <Heading textAlign="center" size="md">Where are you looking to buy?</Heading>
            </CardHeader>
            <VStack align="center" height="100%" alignItems="center">
                <VStack width="100%" maxW="400px" justifyContent="space-between" height="100%">
                    <VStack width="100%">
                        <Text>Where are you planning to buy?</Text>
                        <Select onChange={(e) => setSelectedState(e.target.value)} placeholder='Select a state'>
                            {Object.values(States).map((state) => (
                                <option key={state} value={state}>{state}</option>
                            ))}
                        </Select>
                    </VStack>
                    <Button width="100%" onClick={handleContinue} colorScheme="blue">Continue</Button>
                </VStack>
            </VStack>
        </>
    );
};

export default BuyingLocation;
