import { CardHeader, Heading, VStack, Text, Button } from "@chakra-ui/react";
import OnboardingProgress from "./OnboardingProgress";

const Welcome = ({ onPress, currentStep }: { onPress: () => void, currentStep: number }) => {
    return (
        <>
            <CardHeader>
                <OnboardingProgress currentStep={currentStep} />
                <Heading textAlign="center" size="md">Welcome to Maze</Heading>
            </CardHeader>
            <VStack height="100%" spacing={10} justifyContent="space-between" align="center">
                <Text>Ready to start your home-buying journey? Let’s answer a few quick questions to get you started.</Text>
                <VStack maxW="400px" width="100%">
                    <Button width="100%" onClick={onPress} colorScheme="blue">Get Started</Button>
                </VStack>
            </VStack>
        </>
    );
};

export default Welcome;
