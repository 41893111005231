import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, theme } from "@chakra-ui/react";
import { Field, Form, Formik, FieldProps, FormikProps, FieldInputProps } from 'formik';
import { ReactNode, useState } from "react";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";

type BuyerInfoProps = {
    onContinue: () => void
}

export default function BuyerInfo({ onContinue }: BuyerInfoProps) {

  const [secondBuyerAdded, setSecondBuyerAdded] = useState(false)


  function validateBuyer1Field(value: string) {
    let error
    if (!value || value.length === 0) {
      error = "Required"
    }

    return error
  }

  function validateBuyer2Field(value: string) {
    let error
    if (secondBuyerAdded && !value || value.length === 0) {
      error = "Required"
    }

    return error
  }

  function isFieldInvalid(form: FormikProps<any>, field: FieldInputProps<any>) {
    const error = form.errors[field.name] as string
    const isTouched = form.touched[field.name]
    return isTouched && error && error.length > 0 ? true : false
  }

  return (
    <Flex width="90%" direction="row" justify="center" m={10}>
      <Formik
        initialValues={{
          firstName1: '',
          lastName1: '',
          firstName2: '',
          lastName2: ''
        }}
        onSubmit={(values, actions) => {
          onContinue()
          actions.setSubmitting(false)
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2))
          //   actions.setSubmitting(false)
          // }, 1000)
        }}
      >
        {(props) => (
          <Form style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
            <Flex maxW="500px" width={{base: "100%", md: "80%", lg: "60%"}} height="60%" direction="column" gap={6}>
              <Heading size="md">Buyer 1</Heading>
              <Field name='firstName1' validate={validateBuyer1Field}>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={isFieldInvalid(form, field)}>
                    <FormLabel>First name</FormLabel>
                    <Input {...field} placeholder='first name' />
                    <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name='lastName1' validate={validateBuyer1Field}>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={isFieldInvalid(form, field)}>
                    <FormLabel>Last name</FormLabel>
                    <Input {...field} placeholder='last name' />
                    <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {!secondBuyerAdded && <Button leftIcon={<AiOutlinePlus />} onClick={() => setSecondBuyerAdded(true)} variant="link">Add additional buyer</Button>}
              {
                secondBuyerAdded && (
                  <>
                    <Flex direction="row" justifyContent="space-between">
                      <Heading size="md">Buyer 2</Heading>
                      <Button leftIcon={<AiOutlineClose />} onClick={() => setSecondBuyerAdded(false)} variant="link">Remove buyer</Button>
                    </Flex>
                    <Field name='firstName2' validate={validateBuyer2Field}>
                      {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={isFieldInvalid(form, field)}>
                          <FormLabel>First name</FormLabel>
                          <Input {...field} placeholder='first name' />
                          <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='lastName2' validate={validateBuyer2Field}>
                      {({ field, form }: FieldProps) => (
                        <FormControl isInvalid={isFieldInvalid(form, field)}>
                          <FormLabel>Last name</FormLabel>
                          <Input {...field} placeholder='last name' />
                          <FormErrorMessage>{form.errors[field.name] as ReactNode}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </>
                )
              }

              <Button
                mt={2}
                width="100%"
                colorScheme='blue'
                isLoading={props.isSubmitting}
                type='submit'
                py={4}
              >
                Continue
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}