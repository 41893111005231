import { Button, Center, Flex, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react";
import HomeListItem from "./HomeListItem";
import { useAddListingByZpidMutation, useArchiveUserListingMutation, useGetArchivedUserListingsQuery, useGetUserListingsQuery, useLazySearchListingByAddressQuery, useUnarchiveUserListingMutation } from "../store/api";
import { useEffect, useState } from "react";
import { Home } from "../store/types";

type SavedAndArchivedListings = {
    onMakeAnOffer: () => void
}

export default function SavedAndArchivedListings({ onMakeAnOffer }: SavedAndArchivedListings) {
    const { data: listings, isFetching: isListingsLoading } = useGetUserListingsQuery({})
    const { data: archivedListings, isFetching: isArchivedListingsLoading } = useGetArchivedUserListingsQuery({})
    const { isOpen: isAddListingModalOpen, onOpen: openAddListingModal, onClose: closeAddListingModal } = useDisclosure()

    const [archiveUserListing, { isLoading: isArchivingListing }] = useArchiveUserListingMutation()
    const [unarchiveUserListing, { isLoading: isUnarchivingListing }] = useUnarchiveUserListingMutation()

    // useEffect(() => {
    //     if (listings) {
    //         console.log(listings)
    //     }
    // }, [listings])

    const handleArchiveListing = async (listingId: number) => {
        try {
            await archiveUserListing({ id: listingId })
        } catch (error) {
            console.error(error)
        }
    }

    const handleUnarchiveListing = async (listingId: number) => {
        try {
            await unarchiveUserListing({ id: listingId })
        } catch (error) {
            console.error(error)
        }
    }

    if (isListingsLoading || isArchivingListing || isUnarchivingListing) {
        return (
            <Center height="100%" width="100%">
                <Spinner size="xl" />
            </Center>
        )
    }

    return (
        <Flex flex={1} maxW={1400} width="100%" justify="center" bg="white" p={4}>
            <AddListingModal isOpen={isAddListingModalOpen} onOpen={openAddListingModal} onClose={closeAddListingModal} />
            <Tabs width="100%">
                <TabList>
                    <Tab>Active</Tab>
                    <Tab>Archived</Tab>
                    {listings && listings.length > 0 && <Button pos="absolute" right={0} top={-2} onClick={openAddListingModal}>Add listing</Button>}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Flex flexWrap="wrap" flex={1} height="fit-content" direction="row" width="100%" gap={6} justify="center">
                            {listings && listings.length > 0 ? listings.map((listing) => (
                                <HomeListItem
                                    key={listing.id}
                                    price={listing.price}
                                    beds={listing.bedrooms}
                                    baths={listing.bathrooms}
                                    livingArea={listing.living_area_sqft}
                                    streetAddress={listing.street_address}
                                    city={listing.city}
                                    state={listing.state}
                                    zipCode={listing.zipcode}
                                    imageLink={listing.image_link}
                                    onArchive={handleArchiveListing}
                                    onUnarchive={handleUnarchiveListing}
                                    onMakeAnOffer={onMakeAnOffer}
                                    id={listing.id}
                                    isArchived={false}
                                />
                            )) : (
                                <Button mt={10} size="lg" colorScheme="blue" onClick={openAddListingModal}>Add listing</Button>
                            )}
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Flex flexWrap="wrap" flex={1} height="fit-content" direction="row" width="100%" gap={6} justify="center">
                            {archivedListings && archivedListings.length > 0 ? archivedListings.map((listing) => (
                                <HomeListItem
                                    key={listing.id}
                                    price={listing.price}
                                    beds={listing.bedrooms}
                                    baths={listing.bathrooms}
                                    livingArea={listing.living_area_sqft}
                                    streetAddress={listing.street_address}
                                    city={listing.city}
                                    state={listing.state}
                                    zipCode={listing.zipcode}
                                    imageLink={listing.image_link}
                                    onArchive={handleArchiveListing}
                                    onUnarchive={handleUnarchiveListing}
                                    onMakeAnOffer={onMakeAnOffer}
                                    id={listing.id}
                                    isArchived
                                />
                            )) : (
                                <Flex flexWrap="wrap" flex={1} height="fit-content" direction="row" width="100%" mb={10} gap={6} justify="center">
                                    <Text>No listings have been archived at this time.</Text>
                                </Flex>
                            )}
                        </Flex>

                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    )
}
type AddListingModalProps = {
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void
}

function AddListingModal({ isOpen, onOpen, onClose }: AddListingModalProps) {
    const [address, setAddress] = useState<string>('');
    const [isShowResultsList, setIsShowResultsList] = useState<boolean>(false);
    const [selectedListing, setSelectedListing] = useState<Home>();

    const [search, { data, error, isFetching }] = useLazySearchListingByAddressQuery()
    const [addListing, { data: addListingData, error: addListingError, isLoading: isAddListingLoading }] = useAddListingByZpidMutation()

    const handleSearchClick = () => {
        search({ address })
    }

    const handleAddListingClick = async () => {
        if (selectedListing && selectedListing.zpid) {
            const res = await addListing({ zpid: selectedListing.zpid.toString() })
            // console.log(res)
            onClose()
        }
    }

    useEffect(() => {
        if (data) {
            setIsShowResultsList(true)
            // console.log(data)
        }

        if (error) {
            console.error(error)
        }

        // if (isFetching) {
        //     console.log(isFetching)
        // }
    }, [data, error, isFetching])

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />

            {
                isShowResultsList ? (
                    <ModalContent>
                        <ModalHeader>Search results</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text pb={4}>Select the address for the listing you want to add.</Text>
                            {
                                data && data.length > 0 && data.map((home) => (
                                    <Button colorScheme={selectedListing?.zpid === home.zpid ? 'blue' : undefined} variant="ghost" key={home.id} onClick={() => setSelectedListing(home)}>{home.street_address}, {home.city}, {home.state} {home.zipcode}</Button>
                                ))
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" onClick={() => setIsShowResultsList(false)}>Back</Button>
                            <Button isDisabled={!selectedListing} isLoading={isAddListingLoading} onClick={handleAddListingClick} colorScheme='blue'>Add Listing</Button>
                        </ModalFooter>
                    </ModalContent>
                ) : (
                    <ModalContent height={300}>
                        <ModalHeader>Seach by home address</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Input placeholder='Address' value={address} onChange={(e) => setAddress(e.target.value)} />
                        </ModalBody>
                        <ModalFooter>
                            <Button isLoading={isFetching} onClick={handleSearchClick} colorScheme='blue'>Search</Button>
                        </ModalFooter>
                    </ModalContent>
                )
            }

        </Modal>
    )
}