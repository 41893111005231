import { Card, CardBody, Image, Text, Flex, Link as ChakraLink, Button, CardFooter, CardHeader, Badge, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { AiOutlineMore } from "react-icons/ai";
import { Link } from 'react-router-dom'
import { ROUTES } from '..';
import { useArchiveUserListingMutation } from '../store/api';


enum STATUS {
  OFFER_STARTED,
  OFFER_COMPLETE,
  OFFER_SUBMITTED
}

type HomeListItemProps = {
  price: number
  beds: number | undefined
  baths: number | undefined
  livingArea: number | undefined
  streetAddress: string
  city: string
  state: string
  zipCode: string
  imageLink: string | undefined
  onArchive: (listingId: number) => void
  onUnarchive: (listingId: number) => void
  onMakeAnOffer: () => void
  id: number
  isArchived: boolean
}

export default function HomeListItem({ price, beds, baths, livingArea, streetAddress, city, state, zipCode, imageLink, onArchive, onUnarchive, onMakeAnOffer, id, isArchived }: HomeListItemProps) {
  const statusNumber = getRandomInt(5)
  const status = statusNumber < 4 ? statusNumber : undefined

  return (
    <Flex flexBasis={{base: '100%', lg: '31%'}} height={550}>
      <Card>
        <CardHeader p={0} px={4} pt={4} flex="row" alignItems="center">
          <Flex justify="space-between">
            <Badge py={2} px={4} borderRadius={20} height="fit-content">status</Badge>
            <Menu>
              <MenuButton
                aria-label='menu'
                fontSize={30}
                bg="transparent"
                as={IconButton} 
                icon={<AiOutlineMore />}
              />
              <MenuList>
                {
                  isArchived ? (
                    <MenuItem onClick={() => onUnarchive(id)}>Unarchive</MenuItem>
                  ) : (
                    <MenuItem onClick={() => onArchive(id)}>Archive</MenuItem>
                  )
                }
                
              </MenuList>
            </Menu>
            {/* <IconButton
                aria-label='menu'
                fontSize={30}
                bg="transparent"
                icon={<AiOutlineMore />} 
              /> */}
          </Flex>
        </CardHeader>
        {/* <ChakraLink as={Link} to="/homeDetails" _hover={{opacity: .9, backgroundColor: "#eeeeee"}}> */}
        <CardBody>
          <Image fit="cover" height={200} width="100%" src={imageLink} />
          <Text my={4} fontSize={20} fontWeight={600}>${price.toLocaleString('en-US')}</Text>
          <Flex my={4} direction="row">
            <Flex paddingRight={2} borderRightWidth={1} borderRightColor="gray">
              <Text>{beds} bd</Text>
            </Flex>
            <Flex px={2} borderRightWidth={1} borderRightColor="gray">
              <Text>{baths} ba</Text>
            </Flex>
            <Flex px={2}>
              <Text>{livingArea} sqft</Text>
            </Flex>
          </Flex>
          <Text>{streetAddress}, {city}, {state} {zipCode}</Text>
        </CardBody>
        {/* </ChakraLink> */}
        <Footer onMakeAnOffer={onMakeAnOffer} />
      </Card>
    </Flex>
  )
}

type FooterProps = {
  status?: STATUS | undefined
  onMakeAnOffer: () => void
}

const Footer = ({ status, onMakeAnOffer }: FooterProps) => {
  return (
    <CardFooter>
      {/* <ChakraLink flex={1} as={Link} to={ROUTES.WRITE_OFFER}> */}
      <Button onClick={onMakeAnOffer} colorScheme="blue" width="100%" >Make an offer</Button>
      {/* </ChakraLink> */}
    </CardFooter>
  )

}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}