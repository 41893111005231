import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAfCs8wP8jHRuc_QASJ9WKrojgv635NrME",
//   authDomain: "maze-99977.firebaseapp.com",
//   projectId: "maze-99977",
//   storageBucket: "maze-99977.appspot.com",
//   messagingSenderId: "97258804875",
//   appId: "1:97258804875:web:44f9e3b421ff81cbdce68e"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAfCs8wP8jHRuc_QASJ9WKrojgv635NrME",
  authDomain: "maze-99977.firebaseapp.com",
  projectId: "maze-99977",
  storageBucket: "maze-99977.firebasestorage.app",
  messagingSenderId: "97258804875",
  appId: "1:97258804875:web:44f9e3b421ff81cbdce68e",
  measurementId: "G-Z1NS4XXZ56"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export default firebase