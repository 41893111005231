import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, IconButton, Image, Input, Text, useBreakpointValue, useDisclosure, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Logo from '../FullLogo_Transparent_NoBuffer.png'
import LogoHorizontal from '../FullLogo_Transparent_NoBuffer (1).png'
import { ROUTES } from "..";
import "firebase/compat/auth";
import firebase from '../firebase'
import { useDispatch } from "react-redux";
import { api } from "../store/api";
import { clearToken } from "../store/authSlice";
import { clearOnboarding } from "../store/onboardingSlice";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { IoIosMenu } from "react-icons/io";

export default function Header() {
    const [currentUser, setCurrentUser] = useState(firebase.auth().currentUser)
    const isMobileSize = useBreakpointValue({ base: true, lg: false })

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef<HTMLButtonElement | null>(null)

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            setCurrentUser(user)
        })
        return () => unsubscribe()
    }, [])

    useEffect(() => {
        if (isOpen) {
            onClose()
        }
    }, [window.location.pathname])

    return (
        <Flex bg="white" align="center" justify="space-between" px={{base: 4, lg: 14}} py={2} mb={2}>
            <MenuDrawer isOpen={isOpen} onClose={onClose} btnRef={btnRef} isAuthenticated={!!currentUser} />
            <Flex gap={6} align="center">
                <IconButton
                    aria-label="Open menu"
                    as={IoIosMenu}
                    bg="transparent"
                    onClick={onOpen}
                    ref={btnRef}
                />
                <Link to="/">
                    {isMobileSize ? <Image height={14} src={Logo} /> : <Image height={{base: 20, lg: 10}} src={LogoHorizontal} />}
                </Link>
                {currentUser && !isMobileSize && <AuthenticatedLinksLeft />}
                {!currentUser && !isMobileSize && <AnonymousLinksLeft />}
            </Flex>
            <Flex gap={6}>
                {currentUser && <AuthenticatedLinksRight />}
                {!currentUser && <AnonymousLinksRight />}
            </Flex>
        </Flex>
    )
}

type MenuDrawerProps = {
    isOpen: boolean
    onClose: () => void
    btnRef: React.MutableRefObject<HTMLButtonElement | null>
    isAuthenticated: boolean
}

function MenuDrawer({ isOpen, onClose, btnRef, isAuthenticated }: MenuDrawerProps) {
    return (
        <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>

          <DrawerBody>
            <VStack>
            {
                isAuthenticated ? (
                    <>
                    <AuthenticatedLinksLeft />
                    <AuthenticatedLinksRight />
                    </>
                ) : (
                    <>
                    <AnonymousLinksLeft />
                    <AnonymousLinksRight />
                    </>
                )
            }
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            {/* <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    )
}

function AuthenticatedLinksLeft() {
    return (
        <>
            <Link to={ROUTES.PROGRESS_TRACKER}>Progress Tracker</Link>
        </>
    )
}

function AuthenticatedLinksRight() {
    const dispatch = useDispatch()

    const logout = () => {
        firebase.auth().signOut()
        dispatch(clearToken())
        dispatch(clearOnboarding())
        dispatch(api.util.resetApiState())
    }

    return (
        <>
            <Button variant="link" onClick={logout}>Logout</Button>
        </>
    )
}

function AnonymousLinksLeft() {
    return (
        <>
            <Link to="/">Home</Link>
            <Link to="/faq">FAQ</Link>
        </>
    )
}

function AnonymousLinksRight() {
    return (
        <>
            <Link to="/login">Login</Link>
            <Link to="/signUp">Sign Up</Link>
        </>
    )
}
