import {
    Box,
    Button,
    Center,
    Flex,
    HStack,
    Heading,
    Spinner,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Text,
    Textarea,
    VStack,
    useDisclosure,
    useSteps,
} from "@chakra-ui/react";
import PreparationStep from "../components/PerperationStep";
import SavedAndArchivedListings from "../components/SavedListings";
import { useGetUserPerparationAnswersQuery } from "../store/api";
import { useEffect } from "react";
import BuyerInfo from "./writeOffer/partiesAndProperty/buyerInfo";
import SubscriptionSelection from "../components/SubscriptionSelection";
import HelpPannel from "../components/HelpPannel";

const lastCompletedStep = 2

export default function ProgressTracker() {
    const { data: answers, isFetching: isFetchingAnswers, error: answersError } = useGetUserPerparationAnswersQuery({})
    const { isOpen: isSubscriptionSelectionOpen, onOpen: onSubscriptionSelectionOpen, onClose: onSubscriptionSelectionClose } = useDisclosure()

    const { activeStep, setActiveStep } = useSteps({
        index: 1,
        count: steps.length,
    })

    const handleStepClick = (step: number) => {
        if (step <= lastCompletedStep) {
            setActiveStep(step)
        }
    }

    const handlePreperationComplete = () => {
        setActiveStep(2)
    }

    const handleMakeAnOffer = () => {
        setActiveStep(3)
    }

    useEffect(() => {
        // if (answers) {
        //     console.log(answers)
        // }   
        if (answersError) {
            console.error(answersError)
        }

    }, [answersError])

    debugger
    if (isFetchingAnswers) {
        return (
            <Center height="100%" width="100%">
                <Spinner size="lg" />
            </Center>
        )
    }

    if (!answers) {
        return (
            <Center height="100%" width="100%">
                <Text>Something went wrong</Text>
            </Center>
        )
    }

    return (
        <Flex bg="white" height="100%" width="100%" justifyContent="center">
            <SubscriptionSelection
                isOpen={isSubscriptionSelectionOpen}
                onClose={onSubscriptionSelectionClose}
                onOpen={onSubscriptionSelectionOpen}
            />
            <Flex flex={1} maxW={1800} justify="flex-start" p={4} direction="column">
                <HStack gap={4} width="100%" height="100%">
                    <VStack overflow="scroll" flex={1} height={window.innerHeight - 80} maxHeight={window.innerHeight - 80}>
                        <Stepper display={{ base: 'none', md: 'flex' }} py={10} px={4} width="100%" index={activeStep}>
                            {steps.map((step, index) => (
                                <Step key={index} onClick={() => handleStepClick(index + 1)} style={{ cursor: index + 1 <= lastCompletedStep ? 'pointer' : 'not-allowed' }}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box flexShrink='0'>
                                        <StepTitle>{step.title}</StepTitle>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>

                        {/* {
                            activeStep === 1 ? (
                                <VStack width="100%">
                                    <PreparationStep currentAnswers={answers} onContinue={handlePreperationComplete} />
                                </VStack>
                            ) : activeStep === 2 ? <SavedAndArchivedListings onMakeAnOffer={handleMakeAnOffer} />
                                :
                                <Text>Not the current step</Text>
                        } */}
                        {
                            activeStep === 1 && (
                                <VStack width="100%">
                                    <PreparationStep currentAnswers={answers} onContinue={handlePreperationComplete} />
                                </VStack>
                            )
                        }
                        {
                            activeStep === 2 && <SavedAndArchivedListings onMakeAnOffer={handleMakeAnOffer} />
                        }
                        {
                            activeStep === 3 && <BuyerInfo onContinue={onSubscriptionSelectionOpen} />
                        }
                    </VStack>
                    <HelpPannel currentStep={activeStep} />
                </HStack>
            </Flex>
        </Flex>
    );
}

const steps = [
    { title: 'Preparation' },
    { title: 'Searching' },
    { title: 'Offer' },
    { title: 'Closing' },
]
