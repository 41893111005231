import { CardHeader, Heading, VStack, Text, Button } from "@chakra-ui/react";
import OnboardingProgress from "./OnboardingProgress";
import CustomButtonGroup from "../../components/ButtonGroup";
import { useState } from "react";

const IsFirstTimeHomebuyer = ({ currentStep, onPress }: {
    currentStep: number,
    onPress: (isFirstTimeHomebuyer: boolean) => void
}) => {
    const [isFirstTimeHomebuyer, setIsFirstTimeHomebuyer] = useState<boolean>();

    const handleContinue = () => {
        if (isFirstTimeHomebuyer !== undefined) {
            onPress(isFirstTimeHomebuyer);
        }
    };

    return (
        <>
            <CardHeader>
                <OnboardingProgress currentStep={currentStep} />
                <Heading textAlign="center" size="md">Is this your first time buying a home?</Heading>
            </CardHeader>
            <VStack align="center" height="100%" alignItems="center">
                <VStack width="100%" maxW="400px" justifyContent="space-between" height="100%">
                    <VStack width="100%">
                        <Text>Is this your first time buying a home?</Text>
                        <CustomButtonGroup
                            buttons={[
                                { label: 'Yes', onClick: () => setIsFirstTimeHomebuyer(true) },
                                { label: 'No', onClick: () => setIsFirstTimeHomebuyer(false) },
                            ]}
                        />

                    </VStack>
                    <Button width="100%" colorScheme="blue" onClick={handleContinue}>Continue</Button>
                </VStack>
            </VStack>

        </>
    );
};

export default IsFirstTimeHomebuyer;
