// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  colors: {
    blue: {
        100: "#bbdbdb",
        800: '#3854b0',
        600: '#0c4c74',
        500: '#0c4474',
        900: '#0c4773',
    },
    gray: {
        300: '#737e7e',
        400: '#647474',
        700: '#646c74',
        200: '#444444',
    },
  },
})
