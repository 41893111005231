import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface OnboardingState {
  buying_state: string | null,
  estimated_budget: number | null
  is_preapproved: boolean | null
  property_type: string | null
  is_first_time_buyer: boolean | null
}

const initialState: OnboardingState = {
  buying_state: null,
  estimated_budget: null,
  is_preapproved: null,
  property_type: null,
  is_first_time_buyer: null
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setBuyingState: (state, action: PayloadAction<string>) => {
      state.buying_state = action.payload
    },
    setEstimatedBudget: (state, action: PayloadAction<number>) => {
      state.estimated_budget = action.payload
    },
    setIsPreapproved: (state, action: PayloadAction<boolean>) => {
      state.is_preapproved = action.payload
    },
    setPropertyType: (state, action: PayloadAction<string>) => {
      state.property_type = action.payload
    },
    setIsFirstTimeBuyer: (state, action: PayloadAction<boolean>) => {
      state.is_first_time_buyer = action.payload
    },
    clearOnboarding: (state) => {
      debugger
      state.buying_state = null
      state.estimated_budget = null
      state.is_preapproved = null
      state.property_type = null
      state.is_first_time_buyer = null
    }
  },
})

// Action creators are generated for each case reducer function
export const { setBuyingState, setEstimatedBudget, setIsPreapproved, setPropertyType, setIsFirstTimeBuyer, clearOnboarding } = onboardingSlice.actions

export default onboardingSlice.reducer