import { type MenuProps } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { ReactNode, useState } from "react";
import { HomebuyingJourney } from "../components/HomebuyingJourney";
import HomebuyingStep from "../components/HomeBuyingSteps";
import { ChakraProvider, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Image, Text, Button, Input, Card, CardBody, Icon } from '@chakra-ui/react'
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from '../FullLogo_Transparent_NoBuffer (1).png'
import { Field, FieldInputProps, FieldProps, Form, Formik, FormikProps } from 'formik';
import { FaSackDollar, FaFileLines } from "react-icons/fa6";
import { GiPathDistance } from "react-icons/gi";
import { ROUTES } from '..';
import firebase from '../firebase'
// Required for side-effects
import 'firebase/compat/firestore';
import { IconType } from 'react-icons';
import Header from '../components/Header';
import { theme } from '../theme';
import ErrorBoundary from '../components/ErrorBoundary';

const db = firebase.firestore();

async function addEmail(email: string) {
  try {
    await db.collection("waitlist").doc(email).set({
      createdAt: new Date()
    })
    // console.log("Document successfully written!");
    return true
  } catch (error) {
    console.error("Error writing document: ", error);
    return false
  };
}

export default function Root() {
  const [current, setCurrent] = useState('mail');

  const onClick: MenuProps['onClick'] = (e) => {
    // console.log('click ', e);
    setCurrent(e.key);
  };

  const location = useLocation()

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <Flex flexDir="column" minHeight="100%" align="center" bg="#0C4773">
          <Flex flexDir="column" width="100%">
            <Header />
            <Flex flex={1} justify="center">
              {
                location.pathname === '/' ? <Landing /> : <Outlet />
              }


            </Flex>
          </Flex>
        </Flex>
      </ErrorBoundary>
    </ChakraProvider>
  )

}

const Landing = () => {
  const navigate = useNavigate()

  const [emailSubmitted, setEmailSubmitted] = useState(false)

  function validateEmail(value: string) {
    let error
    if (!value || value.length === 0) {
      error = "Required"
    } else if (!value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      error = "Please enter a valid email"
    }

    return error
  }

  function isFieldInvalid(form: FormikProps<any>, field: FieldInputProps<any>) {
    const error = form.errors[field.name] as string
    const isTouched = form.touched[field.name]
    return isTouched && error && error.length > 0 ? true : false
  }

  async function submitEmail(email: string) {
    const isSuccess = await addEmail(email)
    if (isSuccess) {
      setEmailSubmitted(true)
    } else {
      alert('An error occurred. Please try again.')
    }
  }

  const handleGetStartedClick = () => {
    navigate(ROUTES.SIGN_UP)
  }

  return (
    <Flex maxWidth="1200px" direction="column" align="center" justifyContent="center" flex={1} height="fit-content" py={{ base: 10, lg: 40 }} px={{ base: 10, lg: 0 }}>
      <Flex direction="column" align="center">
        <Heading mb={8} textAlign={{ base: "center", lg: undefined }} size={{ base: "xl", lg: "xl" }} color="white">
          Buy Your Dream Home Without an Agent (and Save Thousands).
        </Heading>
        <Text color="white" textAlign={{ base: "center", lg: undefined }} fontSize={{ base: "lg", lg: "xl" }}>Our platform empowers you to buy your home with confidence, saving you time and money.</Text>
      </Flex>
      <Flex py={10} width="100%">

        <Flex flex={1} justifyContent="center">
          <Button maxW="250px" py={6} flex={1} colorScheme="teal" onClick={handleGetStartedClick}>Get Started Today</Button>
        </Flex>



      </Flex>
      <Flex width="100%" align="center" gap={6} direction={{ lg: "row", base: "column" }} mt={{ base: 6, lg: 20 }}>
        <InfoCard
          text="Avoid unnecessary fees and commissions"
          icon={FaSackDollar}
        />
        <InfoCard
          text="Access clear, easy-to-follow advice at every stage of the home-buying journey."
          icon={GiPathDistance}
        />
        <InfoCard
          text="Generate, review, and manage all essential documents securely in one place."
          icon={FaFileLines}
        />
      </Flex>
    </Flex>
  )
}

type InfoCardProps = {
  text: string
  icon: IconType
}

const InfoCard = ({ text, icon }: InfoCardProps) => {
  return (
    <Card flex={1} maxW={{ base: "800px", lg: "600px" }} width="100%" height="175px">
      <CardBody>
        <Flex gap={4} direction="column" justifyContent="center" align="center">
          <Icon color="#404040" as={icon} boxSize={{ base: 28, lg: 12 }} />
          <Text fontSize={{ base: "lg", lg: 'lg' }} color="#404040" textAlign="center">{text}</Text>
        </Flex>
      </CardBody>
    </Card>
  )
}