import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Text,
    VStack,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    RadioGroup,
    Radio,
    Stack,
    Input,
    Card,
    useDisclosure,
    Spinner,
    Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Wishlist } from "./Wishlist";
import { AddWishlistItem } from "./AddWishlistItem";
import { useGetUserPerparationAnswersQuery, useUpdateUserPerparationAnswersMutation } from "../store/api";
import { PerparationAnswers } from "../store/types";

type PreparationStepProps = {
    currentAnswers: PerparationAnswers,
    onContinue: () => void
}

export default function PerperationStep({ currentAnswers, onContinue }: PreparationStepProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isPreapproved, setIsPreapproved] = useState(currentAnswers.is_preapproved ? '1' : '0')
    const [wishlist, setWishlist] = useState<string[]>([])
    const [sliderValue, setSliderValue] = useState(currentAnswers.maximum_budget)
    const [preapprovedAmount, setPreapprovedAmount] = useState<string>(currentAnswers.preapproval_amount ? formatAsDollarAmount(currentAnswers.preapproval_amount.toString()) : '$0.00')
    const [isPrepQuestionsComplete, setIsPrepQuestionsComplete] = useState(isQuestionsComplete({
        isPreapproved: currentAnswers.is_preapproved,
        preapprovedAmount: currentAnswers.preapproval_amount,
        maximumBudget: currentAnswers.maximum_budget
    }))

    const [updateUserPerparationAnswers] = useUpdateUserPerparationAnswersMutation()

    const addItemToWishlist = (item: string) => {
        setWishlist([...wishlist, item])
        onClose()
    }

    const onChangeIsPreapproved = (value: string) => {
        setIsPreapproved(value)
        updateUserPerparationAnswers({
            is_preapproved: value === '1' ? true : false,
            preapproval_amount: preapprovedAmount.length > 0 ? formattedDollarAmountToNumber(preapprovedAmount) : null,
            maximum_budget: sliderValue ? sliderValue : currentAnswers.maximum_budget
        })
        setIsPrepQuestionsComplete(isQuestionsComplete({
            isPreapproved: value === '1' ? true : false,
            preapprovedAmount: preapprovedAmount.length > 0 ? formattedDollarAmountToNumber(preapprovedAmount) : null,
            maximumBudget: sliderValue
        }))
    }

    const onChangeBudgetValue = (budget: number) => {
        updateUserPerparationAnswers({
            is_preapproved: isPreapproved === '1' ? true : false,
            preapproval_amount: preapprovedAmount.length > 0 ? formattedDollarAmountToNumber(preapprovedAmount) : null,
            maximum_budget: budget
        })
        setIsPrepQuestionsComplete(isQuestionsComplete({
            isPreapproved: isPreapproved === '1' ? true : false,
            preapprovedAmount: preapprovedAmount.length > 0 ? formattedDollarAmountToNumber(preapprovedAmount) : null,
            maximumBudget: budget
        }))
    }

    const onSavePreapprovedAmount = (value: string) => {
        updateUserPerparationAnswers({
            is_preapproved: isPreapproved === '1' ? true : false,
            preapproval_amount: preapprovedAmount.length > 0 ? formattedDollarAmountToNumber(preapprovedAmount) : null,
            maximum_budget: sliderValue ? sliderValue : currentAnswers.maximum_budget
        })
        setIsPrepQuestionsComplete(isQuestionsComplete({
            isPreapproved: isPreapproved === '1' ? true : false,
            preapprovedAmount: preapprovedAmount.length > 0 ? formattedDollarAmountToNumber(preapprovedAmount) : null,
            maximumBudget: sliderValue ? sliderValue : currentAnswers.maximum_budget
        }))
    }
     
    const onChangePreapprovedAmount = (value: string) => {
        const formattedAmount = formatAsDollarAmount(value)
        setPreapprovedAmount(formattedAmount)
    }

    return (
        <VStack gap={6} flex={1} width="100%" maxW="800px">

            <ItemContainer>
                <Box flex={1}>
                    <Heading mb={6} size="md">Set your budget</Heading>
                    <VStack flex={1} spacing={6}>
                        <Box width="100%" display="flex" flexDir={{base: "column", sm: "row"}} justifyContent="space-between">
                            <Text>Have you been pre-approved for a mortgage?</Text>
                            <RadioGroup mt={{base: 4, sm: 0}} onChange={onChangeIsPreapproved} value={isPreapproved}>
                                <Stack direction='row'>
                                    <Radio value='1'>Yes</Radio>
                                    <Radio value='0'>No</Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                        <Box width="100%" display="flex" flexDir={{base: "column", sm: "row"}} justifyContent="space-between">
                            <Text>What's your pre-approved amount?</Text>
                            <Input mt={{base: 4, sm: 0}} value={preapprovedAmount} maxW={200} onChange={(e) => onChangePreapprovedAmount(e.target.value)} onBlur={(e) => onSavePreapprovedAmount(e.target.value)} />
                        </Box>
                        <Box width="100%" display="flex" flexDir="column" justifyContent="space-between">
                            <Text>What's your maxium budget?</Text>
                            <Slider onChangeEnd={onChangeBudgetValue} mt={4} aria-label='slider-ex-1' defaultValue={currentAnswers.maximum_budget} min={0} max={1000000} step={1000} onChange={(val) => setSliderValue(val)}>
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                            <Center>
                            <Text mt={4} fontSize="24px" fontWeight={400}>
                                {sliderValue === 1000000 ? '$1M+' : sliderValue / 1000 === 0 ? '$0' : `$${sliderValue / 1000}K`}
                            </Text>
                            </Center>
                        </Box>
                    </VStack>
                </Box>
            </ItemContainer>
            {/* <ItemContainer>
                <AddWishlistItem isOpen={isOpen} onOpen={onOpen} onClose={onClose} onAddItem={addItemToWishlist} />
                <Box flex={1}>
                    <Heading mb={6} size="md">Define your wants and needs</Heading>
                    <VStack gap={8} flex={1} alignItems="flex-start" justifyContent="space-between">
                        <HStack width="100%" justifyContent="space-between">
                            <Text>Prioritize features like location and size</Text>
                            <Button onClick={onOpen}>Add item to wishlist</Button>
                        </HStack>
                        <VStack width="100%" alignItems="center">
                            <Wishlist wishlist={wishlist} setWishlist={setWishlist} />
                        </VStack>
                    </VStack>
                </Box>
            </ItemContainer> */}
            <Button isDisabled={!isPrepQuestionsComplete} mt={6} width="100%" maxW="800px" colorScheme="blue" onClick={onContinue}>Continue</Button>
        </VStack>
    )
}

const ItemContainer = ({ children }: { children?: React.ReactNode }) => {
    return (
        <Card px={6} py={10} width="100%">
            <Flex>
                {children}
            </Flex>
        </Card>
    )
}

function isQuestionsComplete ({isPreapproved, preapprovedAmount, maximumBudget}: {isPreapproved: boolean, preapprovedAmount: number | null, maximumBudget: number}) {
    debugger
    return isPreapproved && preapprovedAmount && maximumBudget
}

function formattedDollarAmountToNumber(formattedAmount: string) {
    return Number(formattedAmount.replace('$', '').replaceAll(',', ''))
}

function formatAsDollarAmount(amountStr: string) {
    const trimmedAmount = amountStr.trim()
    if (trimmedAmount.length === 0 || trimmedAmount === '$') {
        return '$0.00'
    }
    const currentAmount = amountStr
      .replace('$', '')
      .replaceAll(',', '')
      .replace('.', '');
    const newAmount = currentAmount;
    // Alert.alert(newAmount)
    const formattedAmount =
      '$' +
      (parseInt(newAmount) / 100)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedAmount
  }