import { Button, Text, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Card, Divider, Box, Heading, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { TbAlertTriangle } from "react-icons/tb";
import { GoAlert } from "react-icons/go";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics();

type SubscriptionSelectionProps = {
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void
}

export default function SubscriptionSelection({ isOpen, onOpen, onClose }: SubscriptionSelectionProps) {
    const [selectionMade, setSelectionMade] = useState(false)

    const onSelect = () => {
        setSelectionMade(true)
    }

    const handleClose = () => {
        setSelectionMade(false)
        onClose()
    }

    return (
        <Modal onClose={handleClose} size='full' isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent bg="#0C4773">
                <ModalCloseButton color="white" />

                {
                    !selectionMade ? (
                        <ModalBody width="100%" flexDir="column" display="flex" mt={50} alignItems="center">
                            <Heading mb="5%" color="white" fontWeight={300}>Select a plan</Heading>
                            <Flex direction={{base: "column", lg: "row"}} justify="row" gap={4}>
                                <SubscriptionCard
                                    title="Monthly"
                                    monthlyPrice="$75"
                                    billingPriceDescription="Billed monthly"
                                    description={[]}
                                    valueDescription="Month-to-month flexibility"
                                    onSelect={onSelect}
                                />
                                <SubscriptionCard
                                    title="12 Months"
                                    monthlyPrice="$45"
                                    billingPriceDescription="$540 billed annually"
                                    description={[]}
                                    isPrimary
                                    valueDescription="Best value"
                                    onSelect={onSelect}
                                />
                                <SubscriptionCard
                                    title="6 Months"
                                    monthlyPrice="$60"
                                    billingPriceDescription="$360 billed biannually"
                                    description={[]}
                                    valueDescription="Great value"
                                    onSelect={onSelect}
                                />
                            </Flex>
                        </ModalBody>
                    ) : (
                        <ModalBody width="100%" flexDir="column" display="flex" mt={50} alignItems="center">
                            <Icon as={GoAlert} boxSize={75} color="white" />
                            <Heading m={6} color="white" fontWeight={300}>Oops!</Heading>
                            <Text fontSize="lg" color="white">Sorry, we're not available in your area right now.</Text>
                            <Text fontSize="lg" color="white">We'll let you know as soon as we are.</Text>
                            <Button mt={10} onClick={handleClose}>Close</Button>
                        </ModalBody>
                    )
                }

                <ModalFooter>
                    {!selectionMade && <Button onClick={handleClose}>Cancel</Button>}
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

type SubscriptionCardProps = {
    title: string,
    monthlyPrice: string,
    billingPriceDescription: string,
    description: string[],
    isPrimary?: boolean,
    valueDescription: string,
    onSelect: () => void
}

function SubscriptionCard({ title, monthlyPrice, billingPriceDescription, description, isPrimary = false, valueDescription, onSelect }: SubscriptionCardProps) {
    const handleSelectClick = () => {
        onSelect()
        logEvent(analytics, 'select_subscription', {
            title,
            monthlyPrice
        });
    }
    
    return (
        <Flex height={{base: undefined, lg: "430px"}} maxW="430px" alignItems="end">
            <Card height={isPrimary ? "430px" : "380px"} width="280px">
                <VStack p={6} justify="space-between" height="100%">
                    <VStack width="100%">
                        <Text fontSize="2xl">{title}</Text>
                        <Box border="1px" borderColor="gray.300" width="100%" />
                        <Text mt={2} lineHeight={8} fontSize="4xl">{monthlyPrice} / mo</Text>
                        <Text fontSize="sm">{billingPriceDescription}</Text>
                        <VStack mt={4} gap={2}>
                            {description.map((d) => (
                                <Text fontSize="lg">{d}</Text>
                            ))}
                        </VStack>
                    </VStack>
                    <VStack width="100%">
                        <Button onClick={handleSelectClick} colorScheme={isPrimary ? "blue" : undefined} width="100%">Select</Button>
                        <Text>{valueDescription}</Text>
                    </VStack>
                </VStack>
            </Card>
        </Flex>
    )
}