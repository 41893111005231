import { CardHeader, Heading, VStack, Text, Button } from "@chakra-ui/react";
import OnboardingProgress from "./OnboardingProgress";
import CustomButtonGroup from "../../components/ButtonGroup";
import { useState } from "react";

const PropertyType = ({ onPress, currentStep }: { onPress: (propertyType: string) => void, currentStep: number}) => {
    const [propertyType, setPropertyType] = useState<string>();

    const handleContinue = () => {
        if (propertyType !== undefined) {
            onPress(propertyType);
        }
    };

    return (
        <>
            <CardHeader>
                <OnboardingProgress currentStep={currentStep} />
                <Heading textAlign="center" size="md">What type of property are you looking for?</Heading>
                </CardHeader>
                <VStack align="center" height="100%" alignItems="center">
                <VStack width="100%" maxW="400px" justifyContent="space-between" height="100%">
                    <VStack width="100%">
                    <Text>What type of property are you looking for?</Text>
                    <CustomButtonGroup
                        buttons={[
                            { label: 'Single-family', onClick: () => setPropertyType('Single-family') },
                            { label: 'Condo', onClick: () => setPropertyType('Condo') },
                            { label: 'Townhouse', onClick: () => setPropertyType('Townhouse') },
                            { label: 'Other', onClick: () => setPropertyType('Other') },
                        ]}
                    />
                    
                </VStack>
                <Button width="100%" colorScheme="blue" onClick={handleContinue}>Continue</Button>
                </VStack>
            </VStack>
            
        </>
    );
};

export default PropertyType;
