import {
    Heading,
    Text,
    Textarea,
    VStack,
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
    Center,
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    IconButton,
    useBreakpointValue,
} from "@chakra-ui/react";
import { IoHelpOutline } from "react-icons/io5";

export enum Steps {
    Preparation = 1,
    Searching = 2,
    Offer = 3,
    Closing = 4
}

// const isMobile = true

export default function HelpPannel({ currentStep }: { currentStep: Steps }) {
    const isMobile = useBreakpointValue({ base: true, lg: false })
    const { isOpen, onOpen, onClose } = useDisclosure()

    if (isMobile) {
        return (
            <>
                <IconButton
                    position="absolute"
                    bottom={30}
                    right={30}
                    onClick={onOpen}
                    isRound={true}
                    variant='solid'
                    colorScheme='teal'
                    aria-label='Done'
                    fontSize='40px'
                    height="75px"
                    width="75px"
                    icon={<IoHelpOutline />}
                />
                <Modal onClose={onClose} size="full" isOpen={isOpen}>
                    <ModalOverlay />
                    <ModalContent bg="blue.900">
                        <ModalCloseButton color="white" />
                        <ModalBody>
                            <Content currentStep={currentStep} />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        )
    }

    return (
        <VStack display={{ base: 'none', lg: 'flex' }} height={window.innerHeight - 80} borderTopRightRadius={0} width="40%" maxWidth={500} bg="blue.900">
            <Content currentStep={currentStep} />
        </VStack>
    )
}

function Content({ currentStep }: { currentStep: Steps }) {
    return (
        <>
            <VStack pt={10} pb={4}>
                <Heading lineHeight={4} size="md" color="white">Need More Info?</Heading>
                <Center>
                    <Text textAlign="center" color="lightgray">This panel updates Focused tips and advice for the current step. Need more help? Ask our assistant.</Text>
                </Center>
            </VStack>
            <Box px={4} w="100%">
                <Box borderBottom="1px" borderColor="gray.300" width="100%" />
            </Box>
            <VStack width="100%" flex={1} pt={4}>
                <VStack w="100%" alignItems="flex-start" flex={1} px={4} gap={4}>
                    {getHelpText(currentStep).map((text) => (
                        <UnorderedList key={text} color="lightgray">
                            <ListItem>
                                <Text color="lightgray">{text}</Text>
                            </ListItem>
                        </UnorderedList>
                    ))}
                </VStack>
                {/* <VStack borderTopRightRadius={16} borderTopLeftRadius={10} p={10} width="100%" flex={1} bg="#000042">
                    <VStack flex={1} justifyContent="center">
                        <Heading mb={6} size="md" color="white">How can I help?</Heading>
                    </VStack>
                    <VStack width="100%">
                        <Textarea height={120} resize="none" color="white" placeholder='Ask a question' />
                    </VStack>
                </VStack> */}
            </VStack>
        </>
    )
}

function getHelpText(step: Steps): string[] {
    switch (step) {
        case Steps.Preparation:
            return [
                'Pre-approval is an estimate from a lender of how much you can borrow. It shows sellers you’re serious and helps set realistic expectations for your budget.',
                'This is the loan amount your lender has approved based on your financial profile.',
                'Your maximum budget includes your pre-approved loan amount and any additional funds you’re comfortable spending. This number reflects the absolute limit for your home purchase, factoring in other costs like closing fees or repairs.'
            ]
        case Steps.Searching:
            return [
                'Start typing the address of the property you’re interested in—this can be as specific as the full address or just a street name or ZIP code. We’ll search active listings and show you matching results to select from.',
                'Adding an address helps us find property details from active listings. These details are used to organize your home search, assist with evaluations, and streamline later steps like preparing an offer.',
                'Once you’ve entered some information, select the correct address from the list of results. This ensures we pull accurate details about the property you’re considering.',
                'Add as many addresses as you like to keep track of all the properties you’re considering. This helps you compare options and stay organized throughout your home search.',

            ]
        case Steps.Offer:
            return [
                'Your legal name as it appears on your government-issued ID. This ensures consistency across all legal documents and helps avoid delays in processing the contract.'
            ]
        case Steps.Closing:
            return []
    }
}