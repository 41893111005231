import { CardHeader, Heading, VStack, Text, Button } from "@chakra-ui/react";
import OnboardingProgress from "./OnboardingProgress";
import CustomButtonGroup from "../../components/ButtonGroup";
import { useState } from "react";

const Preapproval = ({ onPress, currentStep }: { onPress: (isPreapproved: boolean) => void, currentStep: number }) => {
    const [value, setValue] = useState<boolean>();

    const handleContinue = () => {
        if (value !== undefined) {
            onPress(value);
        }
    };

    return (
        <>
            <CardHeader>
                <OnboardingProgress currentStep={currentStep} />
                <Heading textAlign="center" size="md">Have you been pre-approved for a mortgage?</Heading>
            </CardHeader>
            <VStack align="center" height="100%" alignItems="center">
                <VStack width="100%" maxW="400px" justifyContent="space-between" height="100%">
                    <VStack width="100%">
                        <Text>Have you been pre-approved for a mortgage?</Text>
                        <CustomButtonGroup
                            buttons={[
                                { label: 'Yes', onClick: () => setValue(true) },
                                { label: 'No', onClick: () => setValue(false) },
                            ]}
                        />

                    </VStack>
                    <Button width="100%" onClick={handleContinue} colorScheme="blue">Continue</Button>
                </VStack>
            </VStack>

        </>
    );
};

export default Preapproval;
