import { Button, Text, Flex, Heading, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React, { Component, ErrorInfo } from 'react';
import { GoAlert } from 'react-icons/go';


function ErrorBoundary({ children }: { children: React.ReactNode }) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <ErrorBoundaryMain isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
            {children}
        </ErrorBoundaryMain>
    )
}

interface Props {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void
    onOpen: () => void
}

interface State {
    hasError: boolean;
}

class ErrorBoundaryMain extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        // console.error('ErrorBoundary caught an error:', error, errorInfo);
        this.props.onOpen()
    }

    handleClose = () => {
        this.props.onClose()
        window.location.reload()
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Modal isOpen={this.props.isOpen} onClose={this.props.onClose} size="full">
                    <ModalOverlay />
                    <ModalContent bg="blue.900" color="white">
                        {/* <ModalHeader>Modal Title</ModalHeader> */}
                        {/* <ModalCloseButton /> */}
                        <ModalBody px={{base: 10, lg: "200px"}} display="flex" flexDirection="column" alignItems="center" mt={{base: 20, md: 50, lg: 100}}>
                            <Icon as={GoAlert} boxSize={75} color="white" />
                            <Heading m={6} color="white" fontWeight={300}>Oops! Something went wrong.</Heading>
                            <Text maxW="800px" textAlign="center" fontSize="lg" color="white">We encountered an unexpected error. Don’t worry—this isn’t your fault. You can try refreshing the page, or if the problem persists, contact support for assistance.</Text>
                            <Button mt={10} onClick={this.handleClose}>Refresh Page</Button>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
