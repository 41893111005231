import React, { useState } from 'react';
import { Button, ButtonGroup, VStack } from '@chakra-ui/react';

interface ButtonGroupProps {
  buttons: { label: string; onClick: () => void; }[];
}

const CustomButtonGroup: React.FC<ButtonGroupProps> = ({ buttons }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  return (
    <VStack width="100%" spacing={2} justifyContent="center" align="center">
      {buttons.map((button, index) => (
        <Button
          key={index}
          width="100%"
          onClick={() => {
            button.onClick();
            setSelectedIndex(index);
          }}
          colorScheme={selectedIndex === index ? "blue" : undefined}
          variant={selectedIndex === index ? "solid" : "outline"}
        >
          {button.label}
        </Button>
      ))}
    </VStack>
  );
};

export default CustomButtonGroup;
